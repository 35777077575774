import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollTopComponent = ({ containerRef }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (containerRef && containerRef.current) {
      containerRef.current.scrollTo(0, 0);
    }
  }, [pathname, containerRef]);

  return null;
};

export default ScrollTopComponent;
