import file from 'assets/svg/file.svg';
import CustomBarChart from 'components/charts/BarChart';
import CustomChart from 'components/charts/Line';
import Filter from 'components/shared/Filter';
import PageHeading from 'components/shared/PageHeading';
import DashboardTable from 'components/shared/Table';
import { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { startOfYear, endOfYear } from 'date-fns';
import { GetCompanyLocation, getProductionGraph, getStatsLocation, getStatsOverall, getTechTimeGraph } from 'services/stats/stats.service';
import TableHeading from 'components/shared/TableHeading';
import Pagination from 'components/shared/Pagination';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import ChartProductin from 'components/charts/ChartProductin';

const Dashboard = () => {
    const [selectedRange, setSelectedRange] = useState([startOfYear(new Date()), endOfYear(new Date())]);
    const user = JSON.parse(localStorage.getItem("user")) || {}
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const [filter, setFilter] = useState('All')
    const [filters, setFilters] = useState()
    const [filterTable, setFilterTable] = useState('weekly')
    const [stats, setStats] = useState(null);
    const [loading, setLoading] = useState(true);
    const [chartLoading, setChartLoading] = useState(true);
    const [avgLoading, setAvgLoading] = useState(true);
    const [statsOverall, setStatsOverall] = useState([]);
    const [locationLoading, setLocationLoading] = useState(true);
    const [avgTimeData, setAvgTimeData] = useState([]);
    const [productionData, setProductionData] = useState([]);
    const tableHeadings = ["Date", "Location", "Num Appt", "Avg. Tech Time on Site  ", "Avg. Production Value", "Total Production", "Reservice Rate"];
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const tableBody = stats?.locationData?.map((location) => [
        location?.week ? `Week ${location.week}` :
            location?.date ? moment(location.date).format('dddd, MMMM D, YYYY') :
                location?.month ? monthNames[location.month - 1] : '',
        location.name,
        `${location?.totalAppointments}`,
        `${location?.avgTimeOnSite ? parseFloat(location.avgTimeOnSite).toFixed(2) : '0'}`,
        `$${location?.avgProduction ? parseFloat(location.avgProduction).toFixed(2) : '0'}`,
        `$${location?.totalProduction}`,
        `${location?.reserviceRate ? parseFloat(location.reserviceRate).toFixed(2) : '0'}%`,
    ]) || [];
    const cardData = [
        { id: 1, title: "Num Apts", value: statsOverall?.totalAppointments || "0", percentage: `${statsOverall?.percentageChanges?.totalAppointmentsChange || "0"}%`, icon: file },
        { id: 2, title: "Avg Tech Time on Site", value: `${statsOverall.avgTimeOnSite || "0"}`, percentage: `${statsOverall?.percentageChanges?.avgTimeOnSiteChange || "0"}%`, icon: file },
        { id: 3, title: "Avg Production Value", value: `$${statsOverall.avgProductionValue || "0"}`, percentage: `${statsOverall?.percentageChanges?.totalProductionChange || "0"}%`, icon: file },
        { id: 4, title: "Total Production", value: `$${statsOverall.totalProduction || "0"}`, percentage: `${statsOverall?.percentageChanges?.avgProductionValueChange || "0"}%`, icon: file },
        { id: 5, title: "Reservice Rate", value: `${statsOverall.reserviceRate || "0"}%`, percentage: `${statsOverall?.percentageChanges?.reserviceRateChange || "0"}%`, icon: file },
    ];
    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const fetchAvgTimeData = async (formattedStartDate, formattedEndDate) => {
        setAvgLoading(true);
        try {
            const response = await getTechTimeGraph(formattedStartDate, formattedEndDate, filterTable, filter);
            console.log("Avg time", response?.data?.data)
            setAvgTimeData(response?.data?.data);
        } catch (error) {
            console.error("Error fetching average time on site data:", error);
        } finally {
            setAvgLoading(false);
        }
    };

    const fetchProductionData = async (formattedStartDate, formattedEndDate) => {
        setChartLoading(true);
        try {
            const response = await getProductionGraph(formattedStartDate, formattedEndDate, filterTable, filter);
            console.log("Production", response?.data?.data)
            setProductionData(response?.data?.data);
        } catch (error) {
            console.error("Error fetching production data:", error);
        } finally {
            setChartLoading(false);
        }
    };
    // const getall = async () => {
    //     // setChartLoading(true);
    //     try {
    //         const response = await getallData();  // Pass filter and year
    //         // setProductionData(response?.data?.productionData);
    //         console.log("all", response?.data?.savedetails)
    //     } catch (error) {
    //         console.error("Error fetching production data:", error);
    //     } finally {
    //         setChartLoading(false);
    //     }
    // };
    const getStats = async (formattedStartDate, formattedEndDate) => {
        try {
            setLoading(true);
            const response = await getStatsLocation(formattedStartDate, formattedEndDate, filterTable, filter);
            // console.log("Response", response?.data?.stats?.locationData?.periods)
            setStats(response?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    const fetchLocation = async () => {
        try {
            const response = await GetCompanyLocation();
            // console.log("Response Location", response?.data?.locations);

            const locationsWithAll = [{ name: 'All', id: 'All' }, ...response?.data?.locations];

            setFilters(locationsWithAll);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };
    const fetchStatsOverall = async (formattedStartDate, formattedEndDate) => {
        setLocationLoading(true);
        try {
            const response = await getStatsOverall(formattedStartDate, formattedEndDate, filterTable, filter);  // Pass filter and year
            setStatsOverall(response?.data?.overall);
            console.log("Location Data Stats", response?.data?.overall)
        } catch (error) {
            console.error("Error fetching production data:", error);
        } finally {
            setLocationLoading(false);
        }
    };
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            setCurrentPage(1);
            fetchLocation();
            getStats(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            fetchAvgTimeData(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            fetchProductionData(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            fetchStatsOverall(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
        }
        // getall();
        // eslint-disable-next-line
    }, [selectedRange, filterTable, filter])
    const color1 = "#3182CE"
    const color2 = "#1CC76D"
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentTableBody = tableBody.slice(indexOfFirstRow, indexOfLastRow);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(tableBody.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const filtersTable = ['weekly', 'daily', 'monthly']
    return (
        <Container className='' fluid>
            <Row className="my-2 pb-3">
                <Row className='allign-items-center'>
                    <Col>
                        <h4 className='mb-0'>Hello {`${capitalizeFirstLetter(user.firstname)} ${capitalizeFirstLetter(user.lastname)}`}

                            👋🏼,
                        </h4>
                    </Col>
                    <Col>
                        <Filter selectedRange={selectedRange} setSelectedRange={setSelectedRange} filters={filters} setFilter={setFilter} activeFilter={filter} filtersTable={filtersTable} setFilterTable={setFilterTable} filterTable={filterTable} />
                    </Col>

                </Row>
            </Row>
            <Row className=" gap-2 ">
                {locationLoading ? (
                    <>
                        {cardData.map((_, idx) => (
                            <Col key={idx} className="mb-4 shadow rounded-4 p-3">
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <Skeleton width={100} height={10} className="mb-2" />
                                        <Skeleton width={60} height={15} />
                                    </div>
                                    <div style={{ width: "30px", height: "20px" }}>
                                        <Skeleton circle={true} height={20} width={30} />
                                    </div>
                                </div>
                                <Skeleton width={100} height={10} className="mt-2" />
                            </Col>
                        ))}
                    </>
                ) : (
                    cardData?.map(card => (
                        <Col key={card?.id} className="mb-4 shadow rounded-4 p-3">
                            <div className="d-flex justify-content-between align-items-center">
                                <div>
                                    <p className="mb-0 fs-12">{card?.title}</p>
                                    <h6 className="mb-0 small fw-semibold text-black">{card?.value}</h6>
                                </div>
                                <div style={{ width: "30px", height: "30px" }}>
                                    <Image src={card?.icon} fluid
                                        alt="icon" />
                                </div>
                            </div>
                            <p className="mb-0 fs-12 mt-3">
                                <span className="primary-color">{card?.percentage}</span> Since Last Month
                            </p>
                        </Col>
                    ))
                )}
            </Row>
            <Row className='py-4 gap-xl-0 gap-4'>
                <Col xl={7} xs={12}>
                    <section style={{
                        boxShadow: "0px 5px 14px 0px #0000000D"
                    }} className='border border-dark p-4 rounded-4'>
                        <div className="d-flex justify-content-between">
                            <div className='pb-4'>
                                <PageHeading fontSize="18px" color="#083932" heading={"Production Over Time"} />
                            </div>
                        </div>
                        {/* <CustomChart
                            data={productionData}
                            dataKeys={['totalProduction']}
                            xAxisKey="timePeriod"
                            defs={true}
                            height={405}
                            marginleft={-2}
                            strokeWidth={2}
                            defColors={[color1, color2]}
                            vr={false}
                            colors={{ stroke: ['#3182CE'] }}
                            legend={false}
                            grid={true}
                            chartLoading={chartLoading}
                            filterTable={filterTable}
                        /> */}
                        <ChartProductin data={productionData} loading={avgLoading} filterTable={filterTable} />
                    </section>

                </Col>
                <Col xl={5} xs={12}>
                    <CustomBarChart data={avgTimeData} loading={avgLoading} filterTable={filterTable} />
                </Col>

            </Row>
            <Row>
                <div className='p-md-5 bg-white rounded-4 m-1'>
                    <div className='d-md-flex justify-content-between  align-items-center mb-md-4'>
                        <TableHeading heading={"Overview"} />
                        <Pagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(tableBody?.length / rowsPerPage)}
                            onNext={handleNextPage}
                            onPrevious={handlePreviousPage}
                            loading={loading}
                        />
                    </div>
                    <DashboardTable tableHeadings={tableHeadings} tableBody={currentTableBody} loading={loading} />
                </div>
            </Row>
        </Container >
    );
};
export default Dashboard;
