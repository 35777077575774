import { useState } from 'react';
import { Button, Col, Container, Form, Image, Row, Alert } from 'react-bootstrap';
import { WaitingListForm } from 'services/authentication/authentication.service';
import waiting from '../assets/icons8-quiz-50.png';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const SignUp = () => {
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState()
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        companyName: '',
        phoneNumber: '',
        email: '',
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSuccess(false);

        try {
            await WaitingListForm(formData);
            // console.log("Response", response);
            // Reset the form data after successful submission
            setFormData({
                firstName: '',
                lastName: '',
                companyName: '',
                phoneNumber: '',
                email: '',
            });
            window.open("https://calendly.com/pestinsights-info/30min", "_blank");
            setSuccess(true); // Set success message
        } catch (err) {
            setError(err?.response?.data?.error || 'error');
            console.error('Error:', err);
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='h-screen position-relative'>
            <div className='signup'></div>

            <div className='signupContent'>
                <Container>
                    <Row>
                        <Col xs={12} className='text-white text-center d-md-block d-none'>
                            <h1>Join Our Waiting List!</h1>
                            <p style={{ color: "#FFFFFF" }}>
                                Sign up to join the waiting list and be the first to know when we launch!
                            </p>
                        </Col>
                        <Col lg={7} md={6} sm={8} xs={12} className='mx-auto'>
                            <div style={{ boxShadow: "0px 5px 14px 0px #0000000D" }} className='bg-white rounded-4 px-xl-5 p-sm-4 p-3 mx-md-0 mx-2'>
                                {/* <h3 className='text-center'>Waiting List</h3> */}
                                <Form onSubmit={handleSubmit}>
                                    <div className='pb-3'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <Image src={waiting} width={'80px'} height={'100px'} style={{ objectFit: 'contain', margin: 'auto' }} />
                                        </div>
                                        <h4 className='text-center'>Waiting List</h4>
                                    </div>
                                    {success && <Alert variant="success" className="text-center">Thank you! You are on the waiting list now.</Alert>}
                                    {error && <Alert variant="danger" className="text-center">{error}</Alert>}

                                    <Row>
                                        <Col md={6}>
                                            <Form.Group controlId="formFirstName" className='mb-3'>
                                                <Form.Label className='text-custom'>First Name*</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="firstName"
                                                    placeholder="Enter your first name"
                                                    value={formData.firstName}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group controlId="formLastName" className='mb-3'>
                                                <Form.Label className='text-custom'>Last Name*</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="lastName"
                                                    placeholder="Enter your last name"
                                                    value={formData.lastName}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group controlId="formCompanyName" className='mb-3'>
                                        <Form.Label className='text-custom'>Company Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="companyName"
                                            placeholder="Enter your company name"
                                            value={formData.companyName}
                                            onChange={handleInputChange}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formPhoneNumber" className='mb-3'>
                                        <Form.Label className='text-custom'>Phone Number</Form.Label>
                                        <PhoneInput
                                            inputClass='custome-phone'
                                            country={'all'}
                                            placeholder='Enter Your phone number'
                                            value={formData.phoneNumber}
                                            // onChange={phone => this.setState({ phone })}
                                            onChange={(phoneNumber) => setFormData({ ...formData, phoneNumber: phoneNumber })}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formEmail" className='mb-3'>
                                        <Form.Label className='text-custom'>Email*</Form.Label>
                                        <Form.Control
                                            type="email"
                                            name="email"
                                            placeholder="Enter your email"
                                            value={formData.email}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </Form.Group>

                                    <Button
                                        type="submit"
                                        className='w-100 py-2'
                                        disabled={loading}
                                        style={{ background: "var(--bg-ltr)", borderColor: "var(--primary-color)" }}
                                    >
                                        <small>{loading ? 'Submitting...' : 'JOIN WAITING LIST'}</small>
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};

export default SignUp;
