import OTPInput from "otp-input-react";
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap'; // Import Spinner
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { Forgot, OTPCheck } from "services/authentication/authentication.service";
const OTP = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { email } = location.state || {};
    const [OTP, setOTP] = useState("");
    const [timer, setTimer] = useState(120);
    const [canResend, setCanResend] = useState(false);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (timer > 0) {
            const countdown = setTimeout(() => setTimer(timer - 1), 1000);
            return () => clearTimeout(countdown);
        } else {
            setCanResend(true);
        }
    }, [timer]);
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        if (OTP.length !== 6) {
            setError('Please enter a valid 6-digit OTP');
            setLoading(false);
            return;
        }
        try {
            const response = await OTPCheck({ code: OTP, email });
            if (response?.data) {
                toast.success("OTP Verified Successfully");
                // localStorage.setItem('otp', OTP);
                navigate('/reset-password', { state: { OTP, email } });
            }
        } catch (err) {
            console.error('Error during OTP verification:', err);
            toast.error("Failed to verify OTP");
            setError('Invalid OTP. Please try again.');
        } finally {
            setLoading(false);
        }
    };
    const handleResendOTP = async () => {
        if (email) {
            try {
                const response = await Forgot({ email });
                console.log('Resending OTP...', response);
                setCanResend(false);
                // setTimer(120);
                toast.success("OTP has been resent to your email!");
                setOTP('');
            } catch (err) {
                console.error('Error resending OTP:', err);
                toast.error('Failed to resend OTP. Please try again later.');
            }
        } else {
            navigate("/forgot-password");
        }
    };
    return (
        <div className='signin d-flex justify-content-center align-items-center min-vh-100'>
            <Row className='w-100'>
                <Col lg={4} md={6} sm={8} xs={12} className='mx-auto'>
                    <div style={{ boxShadow: " 0px 5px 14px 0px #0000000D" }} className='bg-white rounded-4 p-xl-5 p-sm-4 p-3'>
                        <h1 className='text-center mb-4'>Verify OTP</h1>

                        {error && <Alert variant="danger">{error}</Alert>}

                        <Form style={{ width: '100%' }} onSubmit={handleSubmit}>
                            <Form.Group className='mb-3 text-center' controlId="formBasicEmail">
                                <OTPInput
                                    className={"justify-content-center otp"}
                                    value={OTP}
                                    onChange={setOTP}
                                    autoFocus
                                    OTPLength={6}
                                    otpType="number"
                                    disabled={false}
                                />
                            </Form.Group>
                            <div className="text-center">
                                <Button
                                    type="submit"
                                    style={{ background: "var(--bg-ltr)", borderColor: "var(--primary-color)" }}
                                    className='mt-3 w-75 justify-content-center mx-auto button-style py-2'
                                    disabled={loading}
                                >
                                    {loading ? (
                                        <>
                                            <Spinner animation="border" size="sm" className="me-2" /> Sending OTP...
                                        </>
                                    ) : (
                                        "Verify OTP"
                                    )}
                                </Button>
                            </div>
                            <div className='text-center mt-3'>
                                {canResend ? (
                                    <Link to="#" className="text-custom px-1 text-decoration-none" onClick={handleResendOTP} disabled={loading}>Resend OTP?</Link>
                                ) : (
                                    <p>{`Resend code in ${formatTime(timer)}`}</p>
                                )}
                            </div>
                        </Form>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default OTP;
