import React from 'react'
import Navigation from './Navigation'
import ScrollTopComponent from 'utils/ScrollTopComponent'

const App = () => {
  return (
    <>
    <ScrollTopComponent/>
      <Navigation />
    </>
  )
}

export default App