import UserCard from 'components/shared/UserCard';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { deleteCompany, GetCompany, UpdateCompanyStatus } from 'services/admin/admin.service';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';

const Dashboard = () => {
    const navigate = useNavigate();
    const [company, setCompany] = useState();
    const [loading, setLoading] = useState(true)
    const [loadingButton, setLoadingButton] = useState(false)

    const [limit, setLimit] = useState(4);

    const [searchValue, setSearchValue] = useState('');
    const [error, setError] = useState(null);

    const handleInputChange = (e) => {
        setSearchValue(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch();
        }
    };

    const handleSearch = async () => {
        try {
            setError(null);
            console.log("Search Value:", searchValue);
            setTimeout(() => {
                setSearchValue(".")
            }, 2000);
        } catch (err) {
            setError('An error occurred while searching. Please try again.');
            console.error(err);
        }
    };
    const allCompanies = async () => {
        setLoading(true);
        try {
            const response = await GetCompany();
            console.log("Company", response?.data?.data)
            setCompany(response?.data?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }

    const handleToggleChange = async (companyId, currentStatus) => {
        const toastId = toast.loading("Deactivating Company...");
        setLoadingButton(true);
        try {
            const newStatus = currentStatus === true ? false : true;
            await UpdateCompanyStatus(companyId, newStatus);
            toast.update(toastId, {
                render: "Company Deactivated successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            allCompanies();
        } catch (error) {
            setLoadingButton(false);
            console.log("error", error);
            toast.update(toastId, {
                render: error?.response?.data?.error || 'Server error During Deactivating',
                type: "error",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
        } finally {
            setLoadingButton(false)
        }
    };

    const handleDelete = async (companyId) => {
        const toastId = toast.loading("Deleting Company...");
        setLoading(true);
        try {
            await deleteCompany(companyId);
            toast.update(toastId, {
                render: "Company deleted successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            allCompanies();
        } catch (error) {
            toast.update(toastId, {
                render: error?.response?.data?.error || 'Server error During Deleting',
                type: "error",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
        } finally {
            setLoading(false)
        }
    };
    useEffect(() => {
        allCompanies()
    }, [])
    return (
        <Container fluid>
            <h1>Company Management</h1>
            <Row className='py-5'>
                <Col xs={12}>
                    <Form className='d-flex gap-3 align-items-center'>
                        <div className='d-flex align-items-center gap-2 w-100'>
                            <Form.Label className='text-uppercase fw-semibold mb-0 small d-sm-block d-none'>Search</Form.Label>
                            <Form.Control
                                type="text"
                                className='rounded-3'
                                style={{ borderColor: "var(--primary-color)" }}
                                placeholder="Search"
                                onKeyDown={handleKeyDown}
                                value={searchValue}
                                onChange={handleInputChange}
                            />
                        </div>
                        <Button onClick={() => navigate("/add-company")} style={{ width: "170px", background: "var(--bg-ttb-dark)", borderColor: "var(--primary-color)" }} className='rounded-3' disabled={loadingButton}>
                            <span className="mb-0 fw-bold pe-1">&#43;</span>
                            <span className="mb-0 fs-12">New Company</span>
                        </Button>
                    </Form>
                    {error && <p className="text-danger mt-3">{error}</p>}
                </Col>
            </Row>

            <Row>
                {loading ? (
                    Array.from({ length: limit }).map((_, index) => (
                        <Col xs={12} lg={3} key={index}>
                            <Card
                                className='text-center my-3 py-2 border-0'
                                style={{
                                    borderRadius: "20px",
                                    overflow: "hidden",
                                    backgroundColor: "#ffffff",
                                    boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                                }}
                            >
                                <div className='text-end me-2'>
                                    <Skeleton width={24} height={24} circle />
                                </div>
                                <div style={{ position: 'relative', width: "100px", height: "100px", margin: "auto" }}>
                                    <Skeleton width={"100%"} height="100%" style={{ borderRadius: "60px", overflow: "hidden" }} />
                                </div>

                                <Card.Body>
                                    <Card.Title>
                                        <Skeleton width="60%" height={24} />
                                    </Card.Title>
                                    <Card.Text>
                                        <Skeleton width="80%" height={20} />
                                    </Card.Text>
                                    <label>
                                        <Skeleton width={40} height={20} />
                                    </label>
                                    <div className="d-flex gap-4 justify-content-center mt-1">
                                        <Skeleton width={30} height={30} circle />
                                        <Skeleton width={30} height={30} circle />
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))
                ) : company?.length === 0 ? (
                    <Col xs={12}>
                        <div className="text-center my-5">
                            <h5>No Results Found</h5>
                        </div>
                    </Col>
                ) : (
                    company?.map(comp => (
                        <Col key={comp?.id} xxl={3} xl={3} lg={4} md={6} xs={12}>
                            <UserCard
                                loadingButton={loadingButton}
                                id={comp?.id}
                                Name={comp?.name}
                                CreatedAt={comp?.CreatedAt}
                                image={comp?.image}
                                isActive={comp?.isActive}
                                Email={comp?.admin?.email}
                                Phone={comp?.admin?.phone}
                                designation={comp?.admin?.firstname}
                                handleToggleChange={() => handleToggleChange(comp?.id, comp?.isActive)}
                                handleDelete={() => handleDelete(comp?.id)}
                            />
                        </Col>
                    ))
                )}
            </Row>
        </Container>
    );
};

export default Dashboard;
