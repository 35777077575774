import React from 'react';
import { Button } from 'react-bootstrap';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import Skeleton from 'react-loading-skeleton';

const Pagination = ({ currentPage, totalPages, onNext, onPrevious, loading }) => {
    return (
        <div className='d-flex gap-3 align-items-center'>
            {loading ? (
                <Skeleton className='mb-2' width={70} height={25} />
            ) : (
                totalPages !== 0 && (
                    <p className='mb-0 small'>{`${currentPage} of ${totalPages}`}</p>
                )
            )}
            <Button
                className='btn-custom-pag rounded-3 small p-1 px-2'
                onClick={onPrevious}
                disabled={currentPage === 1}
            >
                <FaAngleLeft />
            </Button>
            <Button
                className='btn-custom-pag rounded-3 small p-1 px-2'
                onClick={onNext}
                disabled={currentPage === totalPages}
            >
                <FaAngleRight />
            </Button>
        </div>
    );
};

export default Pagination;
