import { useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ResetPasswordService } from 'services/authentication/authentication.service'; // Assuming this service is used for resetting password
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const ResetPassword = () => {
    const location = useLocation();
    const { OTP, email } = location.state || {};
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError('');
        setSuccess(false);
        if (!password || !confirmPassword) {
            setError('Both password fields are required');
            setLoading(false);
            return;
        }
        if (password !== confirmPassword) {
            setError('Passwords do not match');
            setLoading(false);
            return;
        }
        try {
            if (OTP) {
                const response = await ResetPasswordService({ email, code: OTP, password });
                console.log("Password Reset Response:", response);
                setSuccess(true);
                setLoading(false);
                navigate('/login');
            } else {
                navigate('/otp');
            }
        } catch (err) {
            console.error('Error during password reset:', err);
            setError(err?.response?.data?.error || 'An error occurred, please try again later.');
            setLoading(false);
        }
    };
    return (
        <div className='signin d-flex justify-content-center align-items-center min-vh-100'>
            <Row className='w-100 justify-content-center'>
                <Col lg={4} md={6} sm={8} xs={12}>
                    <div className='bg-white rounded-4 shadow p-xl-5 p-sm-4 p-3'>
                        <h2 className='text-center mb-4'>Reset Password</h2>
                        {error && <Alert variant="danger">{error}</Alert>}
                        {success && (
                            <Alert variant="success">
                                Your password has been successfully reset!
                            </Alert>
                        )}
                        <Form onSubmit={handleSubmit}>
                            <Form.Group controlId="formNewPassword" className="mb-3 position-relative">
                                <Form.Label className="text-custom">New Password</Form.Label>
                                <Form.Control
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder="Enter new password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    disabled={loading}
                                    required
                                />
                                <Button
                                    variant="link"
                                    style={{ marginTop: -3 }}
                                    className="position-absolute text-secondary end-0 top-50  me-2"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                                </Button>
                            </Form.Group>
                            <Form.Group controlId="formConfirmPassword" className="mb-3 position-relative">
                                <Form.Label className="text-custom">Confirm Password</Form.Label>
                                <Form.Control
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    placeholder="Confirm new password"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    disabled={loading}
                                    required
                                />
                                <Button
                                    variant="link"
                                    style={{ marginTop: -3 }}
                                    className="position-absolute text-secondary end-0 top-50  me-2"
                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                >
                                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                </Button>
                            </Form.Group>
                            <Button
                                type="submit"
                                className='w-100 py-2'
                                style={{ background: "var(--bg-ltr)", borderColor: "var(--primary-color)" }}
                                disabled={loading}
                            >
                                {loading ? 'Resetting...' : 'Reset Password'}
                            </Button>
                        </Form>
                        <div className="text-center text-secondary mt-3">
                            <Link to="/login" className='text-decoration-none text-custom px-1'>
                                Back to Sign In
                            </Link>
                        </div>
                    </div>
                </Col >
            </Row >
        </div >
    );
};

export default ResetPassword;
