import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Row, Card, Alert } from 'react-bootstrap';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { addCompany } from 'services/admin/admin.service';
import { CountrySelect } from 'react-country-state-city';
import ConfirmModal from 'components/modals/ConfirmModal';
import { toast } from 'react-toastify';
import PhoneInput from 'react-phone-input-2';
import CreateModal from './Popup';

const AddCompanyForm = () => {
    const location = useLocation();
    const { id } = useParams();
    const [text, setText] = useState();
    const navigate = useNavigate();
    const [modalShow, setModalShow] = useState(false);
    const { userData } = location.state || {};
    const [formData, setFormData] = useState({
        companyName: '',
        companyAddress: '',
        country: '',
        companyPhone: '',
        maxUser: '',
        adminEmail: '',
        adminFirstName: '',
        adminLastName: '',
        adminPhone: '',
        subdomain: '',
        locations: [{ name: '', authenticationKey: '', authenticationToken: '' }],
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({});

    useEffect(() => {
        if (userData) {
            setFormData({
                companyName: userData?.company || '',
                adminFirstName: userData?.firstname || '',
                adminLastName: userData?.lastname || '',
                adminEmail: userData?.email || '',
                adminPhone: userData?.phone || '',
                country: userData?.country || '',
                companyAddress: '',
                companyPhone: '',
                authenticationKey: '', // Default value for new field
                authenticationToken: '', // Default value for new field
                subdomain:''
            });
        }
    }, [userData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setError((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));

        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const handleLocationChange = (index, e) => {
        const { name, value } = e.target;
        const newLocations = [...formData.locations];
        newLocations[index][name] = value;
        setError((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[`locationName${index}`];
            delete newErrors[`authenticationKey${index}`];
            delete newErrors[`authenticationToken${index}`];
            return newErrors;
        });
        setFormData({ ...formData, locations: newLocations });

    };

    const addLocation = () => {
        setFormData({
            ...formData,
            locations: [...formData.locations, { name: '', authenticationKey: '', authenticationToken: '' }],
        });

    };

    const removeLocation = (index) => {
        const newLocations = [...formData.locations];
        newLocations.splice(index, 1);
        setFormData({ ...formData, locations: newLocations });
    };
    const validateForm = () => {
        let formErrors = {};
        console.log("Admin Phone", formData.adminPhone)
        if (!formData.companyName) formErrors.companyName = 'Company name is required';
        if (!formData.companyAddress) formErrors.companyAddress = 'Company address is required';
        if (!formData.country) formErrors.country = 'Country is required';
        if (!formData.companyPhone) formErrors.companyPhone = 'Company phone is required';
        if (!formData.adminEmail) formErrors.adminEmail = 'Admin email is required';
        if (!formData.adminFirstName) formErrors.adminFirstName = 'Admin first name is required';
        if (!formData.adminLastName) formErrors.adminLastName = 'Admin last name is required';
        if (!formData.adminPhone) formErrors.adminPhone = 'Admin phone is required';
        if (!formData.subdomain) formErrors.subdomain = 'Admin phone is required';
        formData.locations.forEach((location, index) => {
            if (!location.name) formErrors[`locationName${index}`] = `Location ${index + 1} name is required`;
            if (!location.authenticationKey) formErrors[`authenticationKey${index}`] = `Location ${index + 1} authentication key is required`;
            if (!location.authenticationToken) formErrors[`authenticationToken${index}`] = `Location ${index + 1} authentication token is required`;
        });
        return formErrors;
    };

    const handleFormSubmit = () => {
        const formErrors = validateForm();
        console.log("Error", formErrors)
        if (Object.keys(formErrors).length > 0) {
            setError(formErrors);
        } else {
            setText('Are You sure you want to create this company?')
            setModalShow(true);
        }
    };

    const handleCreate = async () => {
        setLoading(true);
        setModalShow(false)
        setError({});
        const toastId = toast.loading("Adding Company...");
        try {
            await addCompany(formData);
            setFormData({
                companyName: '',
                companyAddress: '',
                country: '',
                companyPhone: '',
                adminEmail: '',
                adminFirstName: '',
                adminLastName: '',
                adminPhone: '',
                subdomain: '',
                locations: [{ name: '', authenticationKey: '', authenticationToken: '' }],
            });
            toast.update(toastId, {
                render: "Company Added successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            navigate('/dashboard');
        } catch (error) {
            toast.update(toastId, {
                render: error?.response?.data?.error || 'Server error During Deactivating',
                type: "error",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            setError({ general: error?.response?.data?.error || 'An error occurred while adding the user. Please try again.' });
        } finally {
            setLoading(false);
            setModalShow(false);
        }
    };

    const handleCancel = () => {
        navigate(-1);
    };

    return (
        <Container fluid>
            <h1>User Management - Add Company</h1>
            <Form className="pt-2">
                {/* Company Info Card */}
                <Card className="p-4 border-0 mt-4" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                    <h4 style={{ color: '#4E4E4E' }} className="my-4">
                        Company Info
                    </h4>
                    {error.general && <Alert variant="danger">{error.general}</Alert>}
                    <Row className="my-3">
                        {/* Existing fields */}
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyName">
                                <Form.Label className='text-secondary'>Company Name*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.companyName}
                                />
                                <Form.Control.Feedback type="invalid">{error.companyName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formMaxUser">
                                <Form.Label className='text-secondary'>API Subdomain* (e.g. <span className='fw-bold'>subdomain.pestroutes.com</span>) </Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="subdomain"
                                    value={formData.subdomain}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCountry" className='custom-input custome-phone'>
                                <Form.Label className='text-secondary'>Country*</Form.Label>
                                <CountrySelect
                                    inputClassName="border-0"
                                    placeHolder="Select Country"
                                    value={formData.country}
                                    // onChange={(country) => setFormData({ ...formData, country: country.name })}
                                    onChange={(country) => {
                                        setFormData({ ...formData, country: country.name });
                                        setError((prevErrors) => ({
                                            ...prevErrors,
                                            country: '',
                                        }));
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{error.country}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className='custome-phone'>
                                <Form.Label className='text-secondary'>Company Phone Number*</Form.Label>
                                <PhoneInput
                                    inputClass='custome-phone py-4'
                                    country={'all'}
                                    placeholder='Enter Company phone number'
                                    value={formData.companyPhone}
                                    isInvalid={!!error.companyPhone}
                                    onChange={(phone) => {
                                        setFormData({ ...formData, companyPhone: phone });
                                        setError((prevErrors) => ({
                                            ...prevErrors,
                                            companyPhone: '',
                                        }));
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{error.companyPhone}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formMaxUser">
                                <Form.Label className='text-secondary'>Maximum User</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="number"
                                    name="maxUser"
                                    value={formData.maxUser}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyAddress">
                                <Form.Label className='text-secondary'>Company Address*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="companyAddress"
                                    value={formData.companyAddress}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.companyAddress}
                                />
                                <Form.Control.Feedback type="invalid">{error.companyAddress}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    
                </Card>

                {/* Location Keys Info Card */}
                <Card className="p-4 border-0 mt-4" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                    <h4 style={{ color: '#4E4E4E' }} className="my-4">
                        Location Vise Keys Info
                    </h4>
                    <Row>
                        {/* Locations Section */}
                        {formData.locations.map((location, index) => (
                            <div key={index} className="my-3">
                                <h4 style={{ color: '#4E4E4E' }} className="">
                                    Location {index + 1}
                                </h4>
                                <Row>
                                    <Col xs={12} lg={6}>
                                        <Form.Group controlId={`formLocationName${index}`}>
                                            <Form.Label className='text-secondary'>Location Name*</Form.Label>
                                            <Form.Control
                                                size='lg'
                                                type="text"
                                                name="name"
                                                value={location.name}
                                                onChange={(e) => handleLocationChange(index, e)}
                                                isInvalid={!!error[`locationName${index}`]}
                                            />
                                            <Form.Control.Feedback type="invalid">{error[`locationName${index}`]}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    <Col xs={12} lg={6}>
                                        <Form.Group controlId={`formAuthenticationKey${index}`}>
                                            <Form.Label className='text-secondary'>Authentication Key*</Form.Label>
                                            <Form.Control
                                                size='lg'
                                                type="text"
                                                name="authenticationKey"
                                                value={location.authenticationKey}
                                                onChange={(e) => handleLocationChange(index, e)}
                                                isInvalid={!!error[`authenticationKey${index}`]}
                                            />
                                            <Form.Control.Feedback type="invalid">{error[`authenticationKey${index}`]}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className='mt-3 align-items-center'>
                                    <Col xs={12} lg={6}>
                                        <Form.Group controlId={`formAuthenticationToken${index}`}>
                                            <Form.Label className='text-secondary'>Authentication Token*</Form.Label>
                                            <Form.Control
                                                size='lg'
                                                type="text"
                                                name="authenticationToken"
                                                value={location.authenticationToken}
                                                onChange={(e) => handleLocationChange(index, e)}

                                                isInvalid={!!error[`authenticationToken${index}`]}
                                            />
                                            <Form.Control.Feedback type="invalid">{error[`authenticationToken${index}`]}</Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                    {formData.locations.length > 1 && (
                                        <Col xs={12} lg={6} className="d-flex align-items-end">
                                            <Button variant="danger" onClick={() => removeLocation(index)} style={{
                                                borderRadius: '30px',
                                                background: 'linear-gradient(90deg, #ff0000 0%, #800000 100%)',
                                                borderColor: '#ff0000'
                                            }}
                                                disabled={loading}
                                            >
                                                Remove Location
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </div>
                        ))}
                        <Col xs={12}>
                            <Button variant="primary" onClick={addLocation} style={{
                                borderRadius: '30px',
                                background: 'linear-gradient(90deg, #1cc76d 0%, #007a48 100%)',
                                borderColor: '#1cc76d'
                            }}
                                disabled={loading}
                            >
                                Add Another Location
                            </Button>
                        </Col>
                    </Row>
                </Card>

                {/* Admin Info Card */}
                <Card className="p-4 border-0 mt-4" style={{ boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px' }}>
                    <h4 style={{ color: '#4E4E4E' }} className="my-4">
                        Admin Info
                    </h4>
                    <Row className="mt-3">
                        {/* Existing fields */}
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formAdminFirstName">
                                <Form.Label className='text-secondary'>Admin First Name*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="adminFirstName"
                                    value={formData.adminFirstName}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.adminFirstName}
                                />
                                <Form.Control.Feedback type="invalid">{error.adminFirstName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formAdminLastName">
                                <Form.Label className='text-secondary'>Admin Last Name*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="adminLastName"
                                    value={formData.adminLastName}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.adminLastName}
                                />
                                <Form.Control.Feedback type="invalid">{error.adminLastName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-3'>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formAdminEmail">
                                <Form.Label className='text-secondary'>Admin Email*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="email"
                                    name="adminEmail"
                                    value={formData.adminEmail}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.adminEmail}
                                />
                                <Form.Control.Feedback type="invalid">{error.adminEmail}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className='custome-phone'>
                                <Form.Label className='text-secondary'>Admin Phone Number*</Form.Label>
                                <PhoneInput
                                    inputClass='custome-phone py-4'
                                    country={'all'}
                                    placeholder='Enter phone number'
                                    value={formData.adminPhone}
                                    isInvalid={!!error.adminPhone}
                                    onChange={(phone) => {
                                        setFormData({ ...formData, adminPhone: phone });
                                        setError((prevErrors) => ({
                                            ...prevErrors,
                                            adminPhone: '',
                                        }));
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{error.adminPhone}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Card>

                <div className="d-flex justify-content-end mt-4">
                    <Button
                        className="px-4 py-2 submit-btn-hover"
                        style={{
                            borderRadius: '30px',
                            background: 'linear-gradient(90deg, #1cc76d 0%, #007a48 100%)',
                            borderColor: '#1cc76d'
                        }}
                        disabled={loading}
                        onClick={handleFormSubmit}
                    >
                        Add Company
                    </Button>
                </div>
            </Form>
            {/* <ConfirmModal
                text={text}
                show={modalShow}
                handleClose={() => setModalShow(false)}
                handleConfirm={handleCreate}
            /> */}
            <CreateModal
                loading={loading}
                show={modalShow}
                onHide={() => setModalShow(false)}
                onCreate={handleCreate}
            />
        </Container>
    );
};

export default AddCompanyForm;
