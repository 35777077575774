import { GetApiData } from "utils/http-client";

export const getStatsLocation = function (startDate, endDate, filterTable, filter) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/location${query}`, 'GET', null, true);
}
export const getStatsOverall = function (startDate, endDate, filterTable, filter) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/locationstats${query}`, 'GET', null, true);
}
export const GetCompanyLocation = function () {
    return GetApiData(`/company/locations`, "GET", null, true);
};
export const getemployeeStats = function (startDate, endDate, filterTable, filter, employeeID) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}&employeeID=${employeeID}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/employee${query}`, 'GET', null, true);
}

export const getProductionGraph = function (startDate, endDate, filterTable, filter) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/production-over-time${query}`, 'GET', null, true);
}

export const getallData = function () {
    return GetApiData(`/stats/appointment`, 'GET', null, true);
}

export const getAvgProductionGraph = function (startDate, endDate, filterTable, filter) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/avgProduction-over-time${query}`, 'GET', null, true);
}

export const getAvgProductionGraphEmployee = function (startDate, endDate, filterTable, filter, employeeID) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}&employeeID=${employeeID}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/production-employee${query}`, 'GET', null, true);
}

export const getTechTimeGraph = function (startDate, endDate, filterTable, filter) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/avg-time-on-site${query}`, 'GET', null, true);
}

export const getTechTimeGraphEmployee = function (startDate, endDate, filterTable, filter, employeeID) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}&employeeID=${employeeID}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/avg-time-employee${query}`, 'GET', null, true);
}

export const getReserviceGraph = function (startDate, endDate, filterTable, filter) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/reservice${query}`, 'GET', null, true);
}

export const getReserviceGraphEmoloyee = function (startDate, endDate, filterTable, filter, employeeID) {
    // const query = `?startDate=${startDate}&endDate=${endDate}&employeeID=${employeeID}`;
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}&employeeID=${employeeID}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/reserviceEmployee${query}`, 'GET', null, true);
}

export const getTechTime = function (startDate, endDate, filterTable, filter) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/techBreakDown${query}`, 'GET', null, true);
}
export const getReserviceCause = function (startDate, endDate, filterTable, filter, employeeID) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/reservice/${employeeID}${query}`, 'GET', null, true);
}
export const getTechTimeIndividual = function (startDate, endDate, filterTable, filter, employeeID) {
    let query = `?startDate=${startDate}&endDate=${endDate}&type=${filterTable}&employeeId=${employeeID}`;
    if (filter !== 'All') {
        query += `&location=${filter}`;
    }
    return GetApiData(`/stats/techBreakDown/${query}`, 'GET', null, true);
}
