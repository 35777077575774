import clipboard from "assets/icons/clipboard.png";
import fly from "assets/icons/fly.png";
import location from "assets/icons/location.png";
import logout from "assets/icons/logout.png";
import man from "assets/icons/man.png";
import setting from "assets/icons/setting.png";

export const sidebarMenus = [
    {
        visibleOn: ["user", "admin"], // Visible for both user and admin
        icon: location,
        path: "/dashboard",
        label: "Location Overview",
    },
    {
        visibleOn: ["user", "admin"], // Visible for both user and admin
        icon: fly,
        path: "/breakdown",
        label: "Tech Breakdown",
    },
    // {
    //     visibleOn: ["user", "admin"], // Visible for both user and admin
    //     icon: clipboard,
    //     path: "/individual",
    //     label: "Individual Report",
    // },
    {
        visibleOn: ["user", "admin"], // Visible for both user and admin
        icon: man,
        path: "/reservice",
        label: "Re Service Breakdown",
    },
    {
        visibleOn: ["user", "admin"], // Visible for both user and admin
        icon: setting,
        path: "/settings",
        label: "Settings",
    },
    {
        visibleOn: ["admin"], // Visible for admin only
        icon: location,
        path: "/user-management",
        label: "User Management",
    },
    {
        visibleOn: ["superAdmin"], // Visible for superAdmin only
        icon: location,
        path: "/dashboard",
        label: "Company Management",
    },
    {
        visibleOn: ["superAdmin"], // Visible for superAdmin only
        icon: clipboard,
        path: "/add-company",
        label: "Add Company",
    },
    {
        visibleOn: ["superAdmin"], // Visible for superAdmin only
        icon: clipboard,
        path: "/waiting-users",
        label: "Waiting User",
    },
    {
        visibleOn: ["user", "admin", "superAdmin"], // Logout should be visible to all roles
        icon: logout,
        path: "/login",
        label: "Logout",
    },
];
