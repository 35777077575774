import ConfirmModal from 'components/modals/ConfirmModal';
import { useEffect, useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { MdDeleteForever } from "react-icons/md";
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import { DeleteUser } from 'services/admin/admin.service';

const UsersTable = ({ tableBody, companyId, loading, reCall }) => {
    const skeletonRows = Array(10).fill(null);
    const [user, setUser] = useState(tableBody);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [userId, setUserId] = useState(null);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);

    const handleConfirmDelete = async () => {
        const toastId = toast.loading("Deleting User...");
        try {
            setShowDeleteModal(false);
            const response = await DeleteUser(userId, companyId);
            toast.update(toastId, {
                render: "User Deleted successfully!",
                type: "success",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            if (response.status === 200) {
                reCall();
            }
        } catch (error) {
            toast.update(toastId, {
                render: error?.response?.data?.error || 'Server error During Deleting',
                type: "error",
                isLoading: false,
                autoClose: 3000,
                closeOnClick: true,
            });
            console.error("Error deleting user:", error);
        }
    };

    useEffect(() => {
        setUser(tableBody);
    }, [tableBody]);

    const tableHeadings = ["First Name", "Last Name", "User Email", "Phone", "Country", "Action"];

    return (
        <>
            <div className="shadow p-3 rounded-3">
                <Table hover responsive>
                    <thead>
                        <tr>
                            {tableHeadings?.map((th, idx) => (
                                <th className='t-head py-3' style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }} key={idx}>{th}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {loading ? (
                            skeletonRows.map((_, i) => (
                                <tr key={i}>
                                    {tableHeadings.map((_, idx) => (
                                        <td key={idx}><Skeleton height={20} /></td>
                                    ))}
                                </tr>
                            ))
                        ) : user && user?.length > 0 ? (
                            user.map((row, i) => (
                                <tr key={i}>
                                    <td className='py-3'>{row?.firstname}</td>
                                    <td className='py-3'>{row?.lastname}</td>
                                    <td className='py-3'>{row?.email}</td>
                                    <td className='py-3'>{row?.phone}</td>
                                    <td className='py-3'>{row?.country}</td>
                                    <td className='py-3'>
                                        <Button onClick={() => { setShowDeleteModal(true); setUserId(row?.id) }} className='text-danger bg-transparent border-0 p-0'>
                                            <MdDeleteForever />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={tableHeadings?.length} className="text-center py-3">
                                    No users available.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>
            <ConfirmModal
                show={showDeleteModal}
                text='Do you really want to delete this user?'
                handleClose={handleCloseDeleteModal}
                handleConfirm={handleConfirmDelete}
            />
        </>
    );
};

export default UsersTable;
