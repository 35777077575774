import React from 'react';
import { Table } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const DashboardTableSkeleton = ({ rowCount = 5, columnCount = 6 }) => {
    return (
        <div className="shadow p-3 rounded-3">
            <Table hover responsive>
                <thead>
                    <tr>
                        {[...Array(columnCount)].map((_, idx) => (
                            <th style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }} key={idx}>
                                <Skeleton height={20} width={120} />
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {[...Array(rowCount)].map((_, rowIdx) => (
                        <tr key={rowIdx}>
                            {[...Array(columnCount)].map((_, colIdx) => (
                                <td key={colIdx} style={{ color: "#718096" }}>
                                    <Skeleton height={20} />
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default DashboardTableSkeleton;
