import ReserviceRate from 'components/charts/ReserviceRate';
import Filter from 'components/shared/Filter';
import DashboardTable from 'components/shared/Table';
import { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { GetCompanyLocation, getReserviceGraph, getTechTime } from 'services/stats/stats.service';
import { startOfYear, endOfYear } from 'date-fns';
import TableHeading from 'components/shared/TableHeading';
import Pagination from 'components/shared/Pagination';
import moment from 'moment';
import { Link } from 'react-router-dom';
const ReService = () => {
    const [selectedRange, setSelectedRange] = useState([startOfYear(new Date()), endOfYear(new Date())]);
    const [reserviceData, setReserviceData] = useState([]);
    const [chartLoading, setChartLoading] = useState(true);
    const [filter, setFilter] = useState('All')
    const [filters, setFilters] = useState()
    const [filterTable, setFilterTable] = useState('weekly')
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const [loading, setLoading] = useState(true);
    const [techData, setTechData] = useState([]);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const tableHeadings = [
        "Date",
        "Location",
        "Serviced By",
        "Reservice Rate",
        "Reservice Caused"
    ];

    const tableBody = techData?.map((location) => [
        location?.week ? `Week ${location.week}` :
            location?.date ? moment(location.date).format('dddd, MMMM D, YYYY') :
                location?.month ? monthNames[location.month - 1] : '',
        location?.location,
        location?.reserviceCaused > 0 ? (
            <Link className='text-decoration-none' to={`/reservice/${location?.serviceEmployee?.employeeID}`} key={location?.serviceEmployee?.employeeID}>
                {`${location?.serviceEmployee?.fname || ''} ${location?.serviceEmployee?.lname || ''}`}
            </Link>
        ) : (
            `${location?.serviceEmployee?.fname || ''} ${location?.serviceEmployee?.lname || ''}`
        ),
        `${location?.reserviceRate ? parseFloat(location.reserviceRate).toFixed(2) : '0'}%`,
        // `${location?.reserviceRate}`,
        `${location?.reserviceCaused}`,
    ]) || [];


    const fetchReserviceData = async (formattedStartDate, formattedEndDate) => {
        setChartLoading(true);
        try {
            const response = await getReserviceGraph(formattedStartDate, formattedEndDate, filterTable, filter);  // Pass filter
            console.log("Avg time filter grapgh", response?.data?.data)
            setReserviceData(response?.data?.data);
        } catch (error) {
            console.error("Error fetching average time on site data:", error);
        } finally {
            setChartLoading(false);
        }
    };
    const fetchTech = async (formattedStartDate, formattedEndDate) => {
        setLoading(true);
        try {
            const response = await getTechTime(formattedStartDate, formattedEndDate, filterTable, filter);  // Pass filter and year
            setTechData(response?.data?.data);
            console.log("Tech", response?.data?.data)
        } catch (error) {
            console.error("Error fetching production data:", error);
        } finally {
            setLoading(false);
        }
    };
    const fetchLocation = async () => {
        try {
            // setLoading(true);
            const response = await GetCompanyLocation();
            console.log("Response Location", response?.data?.locations)
            const locationsWithAll = [{ name: 'All', id: 'All' }, ...response?.data?.locations];
            setFilters(locationsWithAll);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            // setLoading(false);
        } finally {
            // setLoading(false);
        }
    }
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            setCurrentPage(1);
            fetchLocation();
            fetchTech(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            fetchReserviceData(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
        }
        // eslint-disable-next-line
    }, [selectedRange, filterTable, filter]);
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentTableBody = tableBody.slice(indexOfFirstRow, indexOfLastRow);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(tableBody.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const filtersTable = ['weekly', 'daily', 'monthly']
    return (
        <Container className='mb-5' fluid>
            <Row className="mt-2">
                <Row className='allign-items-center gap-xl-0 gap-2'>
                    <Col>
                        <h1>Re Service Breakdown</h1>
                    </Col>
                    <Col >
                        <Filter selectedRange={selectedRange} setSelectedRange={setSelectedRange} filters={filters} setFilter={setFilter} activeFilter={filter} filtersTable={filtersTable} setFilterTable={setFilterTable} filterTable={filterTable} />
                    </Col>

                </Row>
            </Row>

            <Row className='py-4 gap-xl-0 gap-4'>
                <Col xs={12}>
                    <section className='p-4 pb-0 rounded-4'>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Reservice Rate</h5>
                        </div>
                        <ReserviceRate data={reserviceData} loading={chartLoading} filterTable={filterTable} />
                    </section>
                </Col>


            </Row >
            <Row>
                <div className='p-md-5 bg-white rounded-4 m-1'>
                    <div className='d-md-flex justify-content-between  align-items-center mb-md-4'>
                        <TableHeading heading={"Re Service Breakdown Details"} />
                        <Pagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(tableBody?.length / rowsPerPage)}
                            onNext={handleNextPage}
                            onPrevious={handlePreviousPage}
                            loading={loading}
                        />
                    </div>
                    <DashboardTable tableHeadings={tableHeadings} tableBody={currentTableBody} loading={loading} />
                </div>
            </Row>

        </Container >
    );
};

export default ReService;
