import { useState } from 'react';
import { Button, Col, Container, Form, Modal, Row, Alert } from 'react-bootstrap';
import 'react-phone-input-2/lib/style.css';
import CreateModal from 'views/admin/Popup'; // Ensure this is the correct modal for your confirmation
import { addLocation } from 'services/admin/admin.service';

const AddLocationModal = ({ show, close, companyId, reCall }) => {
    const [modalShow, setModalShow] = useState(false);
    const [error, setError] = useState({});
    const [errorResponse, setErrorResponse] = useState(null);
    const [formData, setFormData] = useState({
        name: '', authenticationKey: '', authenticationToken: ''
    });
    const [loading, setLoading] = useState(false);

    const handleLocationChange = (e) => {
        const { name, value } = e.target;
        setError((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[name];
            return newErrors;
        });
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = 'Location name is required';
        if (!formData.authenticationKey) formErrors.authenticationKey = 'Authentication key is required';
        if (!formData.authenticationToken) formErrors.authenticationToken = 'Authentication token is required';
        return formErrors;
    };

    const handleFormSubmit = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setError(formErrors);
        } else {
            setModalShow(true);
        }
    };
    const handleCreate = async () => {
        setLoading(true);
        setErrorResponse(null);
        setModalShow(false);
        console.log("Company", companyId)
        console.log("Company", formData)
        try {
            await addLocation({ locations: [formData] }, companyId);
            setFormData({ name: '', authenticationKey: '', authenticationToken: '' });
            reCall();
            close();
        } catch (err) {
            setErrorResponse(err?.response?.data?.error || 'Network Error');
            console.error('Error:', err);
        } finally {
            setLoading(false);
            setModalShow(false);
        }
    };
    const handleCancel = () => {
        close();
        setError({});
        setFormData({ name: '', authenticationKey: '', authenticationToken: '' });
    };

    return (
        <Modal show={show} onHide={close} centered size="lg" className='py-0 my-0'>
            <Modal.Header className='border-0' closeButton />
            <Container>
                <Form className='pb-3 px-4'>
                    <h3 className='text-center mb-4 text-box' style={{ color: "var(--primary-color)" }}>Add Location</h3>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formLocationName">
                                <Form.Label className='text-secondary'>Location Name*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleLocationChange}
                                    isInvalid={!!error.name}
                                />
                                <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formAuthenticationKey">
                                <Form.Label className='text-secondary'>Authentication Key*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="authenticationKey"
                                    value={formData.authenticationKey}
                                    onChange={handleLocationChange}
                                    isInvalid={!!error.authenticationKey}
                                />
                                <Form.Control.Feedback type="invalid">{error.authenticationKey}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row className='my-3'>
                        <Col md={6} xs={12}>
                            <Form.Group controlId="formAuthenticationToken">
                                <Form.Label className='text-secondary'>Authentication Token*</Form.Label>
                                <Form.Control
                                    size='lg'
                                    type="text"
                                    name="authenticationToken"
                                    value={formData.authenticationToken}
                                    onChange={handleLocationChange}
                                    isInvalid={!!error.authenticationToken}
                                />
                                <Form.Control.Feedback type="invalid">{error.authenticationToken}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    {errorResponse && <Alert variant="danger" className="text-center">{errorResponse}</Alert>}
                    <div className="d-flex justify-content-between pt-2">
                        <Button onClick={handleCancel} className='bg-secondary border-0 px-4 py-2' disabled={loading}>Cancel</Button>
                        <Button type="button" className='bg-primary-ltr border-0 px-4 py-2 submit-btn-hover' onClick={handleFormSubmit} disabled={loading}>
                            {loading ? 'Creating...' : 'Create'}
                        </Button>
                    </div>
                </Form>

                <CreateModal
                    loading={loading}
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    onCreate={handleCreate}
                />
            </Container>
        </Modal>
    );
};

export default AddLocationModal;
