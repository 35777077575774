import AvgProductionChart from 'components/charts/AvgProductionChart';
import CustomBarChart from 'components/charts/BarChart';
import ReserviceRate from 'components/charts/ReserviceRate';
import Filter from 'components/shared/Filter';
import { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa';
import { FaCalendarDays, FaCaretUp, FaChartColumn } from 'react-icons/fa6';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import { startOfYear, endOfYear } from 'date-fns';
import { getAvgProductionGraphEmployee, GetCompanyLocation, getemployeeStats, getReserviceGraphEmoloyee, getTechTimeGraphEmployee, getTechTimeIndividual } from 'services/stats/stats.service';
import moment from 'moment';
import TableHeading from 'components/shared/TableHeading';
import DashboardTable from 'components/shared/Table';
import Pagination from 'components/shared/Pagination';

const Individual = () => {
    const [selectedRange, setSelectedRange] = useState([startOfYear(new Date()), endOfYear(new Date())]);
    const { id } = useParams();
    const [stats, setStats] = useState(null);
    const [reserviceData, setReserviceData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [avgLoading, setAvgLoading] = useState(true);
    const [chartLoading, setChartLoading] = useState(true);
    const [reserviceLoading, setReserviceLoading] = useState(true);
    const [productionData, setProductionData] = useState([]);
    const [avgTimeData, setAvgTimeData] = useState([]);
    const [filter, setFilter] = useState('All')
    const [filters, setFilters] = useState()
    const [filterTable, setFilterTable] = useState('weekly')
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const [techData, setTechData] = useState([]);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const tableHeadings = [
        "Date",
        "Location",
        "Serviced By",
        "Total Appts",
        "Avg. Tech Time on Site",
        "Total Tech Time on Site",
        "Avg. Production Value",
        "Total Production",
        "Reservice Rate",
        "Hourly Productive Rate",
    ];
    const tableBody = techData?.map((location) => [
        location?.week ? `Week ${location.week}` :
            location?.date ? moment(location.date).format('dddd, MMMM D, YYYY') :
                location?.month ? monthNames[location.month - 1] : '',
        location?.location,
        `${location?.serviceEmployee?.fname || ''} ${location?.serviceEmployee?.lname || ''}`,
        `${location?.totalAppointments}`,
        `${location?.avgTimeOnSite ? parseFloat(location.avgTimeOnSite).toFixed(2) : '0'}`,
        `${location?.totalTimeOnSite ? parseFloat(location.totalTimeOnSite).toFixed(2) : '0'}`,
        `$${location?.avgProduction ? parseFloat(location.avgProduction).toFixed(2) : '0'}`,
        `$${location?.totalProduction}`,
        `${location?.reserviceRate ? parseFloat(location.reserviceRate).toFixed(2) : '0'}%`,
        `$${location?.productiveHourlyRate}`
    ]) || [];
    const getStats = async (formattedStartDate, formattedEndDate) => {
        try {
            setLoading(true);
            const response = await getemployeeStats(formattedStartDate, formattedEndDate, filterTable, filter, id);
            console.log("Response Emplyee Stats", response?.data)
            setStats(response?.data);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    const fetchAvgProductionData = async (formattedStartDate, formattedEndDate) => {
        setChartLoading(true);
        try {
            const response = await getAvgProductionGraphEmployee(formattedStartDate, formattedEndDate, filterTable, filter, id);
            setProductionData(response?.data?.data);
            console.log("Production", response?.data?.data)
        } catch (error) {
            console.error("Error fetching production data:", error);
        } finally {
            setChartLoading(false);
        }
    };
    const fetchAvgTimeData = async (formattedStartDate, formattedEndDate) => {
        setAvgLoading(true);
        try {
            const response = await getTechTimeGraphEmployee(formattedStartDate, formattedEndDate, filterTable, filter, id);  // Pass filter
            console.log("Avg time", response?.data?.data)
            setAvgTimeData(response?.data?.data);
        } catch (error) {
            console.error("Error fetching average time on site data:", error);
        } finally {
            setAvgLoading(false);
        }
    };
    const fetchReserviceData = async (formattedStartDate, formattedEndDate) => {
        setReserviceLoading(true);
        try {
            const response = await getReserviceGraphEmoloyee(formattedStartDate, formattedEndDate, filterTable, filter, id);  // Pass filter
            console.log("Avg time", response?.data?.data)
            setReserviceData(response?.data?.data);
        } catch (error) {
            console.error("Error fetching average time on site data:", error);
        } finally {
            setReserviceLoading(false);
        }
    };
    const fetchLocation = async () => {
        try {
            setLoading(true);
            const response = await GetCompanyLocation();
            console.log("Response Location", response?.data?.locations)
            const locationsWithAll = [{ name: 'All', id: 'All' }, ...response?.data?.locations];
            setFilters(locationsWithAll);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    const fetchTech = async (formattedStartDate, formattedEndDate) => {
        setChartLoading(true);
        try {
            const response = await getTechTimeIndividual(formattedStartDate, formattedEndDate, filterTable, filter, id);  // Pass filter and year
            setTechData(response?.data?.data);
            console.log("Tech", response?.data?.data)
        } catch (error) {
            console.error("Error fetching production data:", error);
        } finally {
            setChartLoading(false);
        }
    };
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            // getStats(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            // fetchAvgTimeData(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            setCurrentPage(1);
            fetchLocation();
            getStats(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            fetchAvgProductionData(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            fetchAvgTimeData(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            fetchReserviceData(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
            fetchTech(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);

        }
        // eslint-disable-next-line
    }, [selectedRange, filterTable, filter]);

    const color1 = "#FF9FB8"
    const color2 = "#1CC76D"
    const filtersTable = ['weekly', 'daily', 'monthly']
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentTableBody = tableBody.slice(indexOfFirstRow, indexOfLastRow);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(tableBody.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    return (
        <Container className='mb-5' fluid>
            <Row className="my-2 pb-3">
                <Row className='allign-items-center'>
                    <Col>
                        <h1>Individual Report</h1>
                    </Col>
                    <Col>
                        <Filter selectedRange={selectedRange} setSelectedRange={setSelectedRange} filters={filters} setFilter={setFilter} activeFilter={filter} filtersTable={filtersTable} setFilterTable={setFilterTable} filterTable={filterTable} />
                    </Col>

                </Row>
            </Row>

            {/* <Filter filters={filters} setFilter={setFilter} activeFilter={filter} /> */}
            <Row className='py-4 gap-xl-0 gap-4'>
                <Col xl={8} xs={12}>
                    <section style={{
                        backgroundColor: "#F6F6FB"
                    }} className='p-4 rounded-4'>
                        <div className="d-flex justify-content-between align-items-center">
                            <h5>Avg. Production Value</h5>
                            {loading ? (
                                <Skeleton width={150} height={40} />
                            ) : (
                                <h2 style={{ color: "var(--primary-color)" }}>${stats?.currentMetrics?.avgProductionValue}</h2>
                            )}
                        </div>
                        <hr />
                        <AvgProductionChart
                            data={productionData}
                            dataKeys={['avgProduction']}
                            xAxisKey="timePeriod"
                            defs={true}
                            height={405}
                            marginleft={-2}
                            strokeWidth={2}
                            defColors={[color1, color2]}
                            vr={false}
                            colors={{ stroke: ['#3182CE'] }}
                            legend={false}
                            grid={true}
                            chartLoading={chartLoading}
                            filterTable={filterTable}
                        />


                    </section>

                </Col>
                <Col xl={4} xs={12}>
                    <Row className='mb-3 gap-3 d-flex flex-sm-row flex-column'>
                        <Col className='p-0 '>
                            <Card className='breakdown-card rounded-4'>
                                <div className="d-flex justify-content-between py-4">
                                    <div>
                                        <FaChartColumn className="card-icon" />
                                    </div>
                                    {loading ? (
                                        <Skeleton width={60} height={20} />
                                    ) : (
                                        <div>
                                            <FaCaretUp className='dropdown-icon' /> <span>{stats?.percentageChanges?.totalAppointmentsChange || "0"}%</span>
                                        </div>
                                    )}
                                </div>
                                {loading ? (
                                    <Skeleton className='mb-2' width={90} height={30} />
                                ) : (
                                    <h2>{stats?.currentMetrics?.totalAppointments || "0"}</h2>
                                )}
                                <p className="mb-0 small">Total Appointments</p>
                            </Card>
                        </Col>

                        <Col className='p-0 '>
                            <Card className='breakdown-card rounded-4'>
                                <div className="d-flex justify-content-between py-4">
                                    <div>
                                        <FaCalendarDays className="card-icon" />
                                    </div>
                                    {loading ? (
                                        <Skeleton width={60} height={20} />
                                    ) : (
                                        <div>
                                            <FaCaretUp className='dropdown-icon' /> <span>{stats?.percentageChanges?.avgTimeOnSiteChange || "0"}%</span>
                                        </div>
                                    )}
                                </div>
                                {loading ? (
                                    <Skeleton className='mb-2' width={90} height={30} />
                                ) : (
                                    <h2>{stats?.currentMetrics?.avgTimeOnSite || "0"}</h2>
                                )}
                                <p className="mb-0 small">Avg. Time on Site</p>
                            </Card>
                        </Col>
                    </Row>

                    <Row className='gap-3 d-flex flex-sm-row flex-column'>
                        <Col className='p-0'>
                            <Card className='breakdown-card rounded-4'>
                                <div className="d-flex justify-content-between py-4">
                                    <div>
                                        <FaUserAlt className="card-icon" />
                                    </div>
                                    {loading ? (
                                        <Skeleton width={60} height={20} />
                                    ) : (
                                        <div>
                                            <FaCaretUp className='dropdown-icon' /> <span>{stats?.percentageChanges?.reserviceRateChange || "0"}%</span>
                                        </div>
                                    )}
                                </div>
                                {loading ? (
                                    <Skeleton className='mb-2' width={90} height={30} />
                                ) : (
                                    <h2>{stats?.currentMetrics?.reserviceRate || "0"}%</h2>
                                )}
                                <p className="mb-0 small">Reservice Rate</p>
                            </Card>
                        </Col>
                        <Col className='p-0 '>
                            <Card className='breakdown-card rounded-4'>
                                <div className="d-flex justify-content-between py-4">
                                    <div>
                                        <FaChartColumn className="card-icon" />
                                    </div>
                                    {loading ? (
                                        <Skeleton width={60} height={20} />
                                    ) : (
                                        <div>
                                            <FaCaretUp className='dropdown-icon' /> <span>{stats?.percentageChanges?.avgProductionValueChange || "0"}%</span>
                                        </div>
                                    )}
                                </div>
                                {loading ? (
                                    <Skeleton className='mb-2' width={90} height={30} />
                                ) : (
                                    <h2>${stats?.currentMetrics?.avgProductionValue || "0"}</h2>
                                )}
                                <p className="mb-0 small">Avg. Production Value</p>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row >

            <Row className='py-4 gap-xl-0 gap-4'>
                <Col xl={8} xs={12}>
                    <ReserviceRate data={reserviceData} loading={reserviceLoading} filterTable={filterTable} />
                </Col>
                <Col xl={4} xs={12}>
                    <CustomBarChart data={avgTimeData} loading={avgLoading} filterTable={filterTable} />
                </Col>

            </Row>
            <Row>
                <div className='p-md-5 bg-white rounded-4 m-1'>
                    <div className='d-md-flex justify-content-between  align-items-center mb-md-4'>
                        <TableHeading heading={"Breakdown Details"} />
                        <Pagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(tableBody?.length / rowsPerPage)}
                            onNext={handleNextPage}
                            onPrevious={handlePreviousPage}
                            locationLoading={loading}
                        />
                    </div>
                    <DashboardTable tableHeadings={tableHeadings} tableBody={currentTableBody} loading={chartLoading} techData={techData} />
                </div>
            </Row>
        </Container >
    );
};

export default Individual;
