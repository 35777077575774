import PageHeading from 'components/shared/PageHeading';
import moment from 'moment';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, Legend, Rectangle } from 'recharts';

// const data = [
//     {
//         "timePeriod": "Aug",
//         "location": "charleston",
//         "reserviceRate": "26.0797546012269939"
//     },
//     {
//         "timePeriod": "Aug",
//         "location": "miami",
//         "reserviceRate": "28.5376879746141732"
//     },
//     {
//         "timePeriod": "Sep",
//         "location": "charleston",
//         "reserviceRate": "24.8643162393162393"
//     },
//     {
//         "timePeriod": "Sep",
//         "location": "miami",
//         "reserviceRate": "27.983125478654321"
//     }
// ];
const lightenColor = (hex, percent) => {
    const num = parseInt(hex.slice(1), 16);
    const amt = Math.round(0.95 * percent); // Adjust the percentage for lightening
    const R = (num > 16) + amt;
    const G = (num >> 8 & 0x00FF) + amt;
    const B = (num & 0x0000FF) + amt;

    return `#${(0x1000000 + (R < 255 ? R < 1 ? 0 : R : 255) * 0x10000 + (G < 255 ? G < 1 ? 0 : G : 255) * 0x100 + (B < 255 ? B < 1 ? 0 : B : 255)).toString(16).slice(1)}`;
};
const colorPalette = ['#7CB342', '#FF7043', '#5E35B1', '#EC407A', '#03A9F4'];
const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const CustomTooltip = ({ active, payload, label, tooltipStyles, valueFormatter, filterTable }) => {
    const formatXAxisLabel = (tick) => {
        if (filterTable === 'weekly') {
            const weekString = `Week ${tick}`
            const weekWithoutYear = weekString?.replace(/-\d{4}/, '');
            return weekWithoutYear;
        } else if (filterTable === 'daily') {
            return moment(tick).format('MMMM D, YYYY');
        } else if (filterTable === 'monthly') {
            const monthNumber = parseInt(tick?.split("-")[1], 10);
            return monthNames[monthNumber - 1] || tick;
        }
        return tick; // Fallback in case no format is applied
    };
    if (active && payload && payload.length) {
        return (
            <div style={tooltipStyles.container}>
                <p style={tooltipStyles.label}>{`${formatXAxisLabel(label)}`}</p>
                {payload?.map((entry, index) => (
                    <p key={`tooltip-item-${index}`} style={{ ...tooltipStyles.value, color: entry.color }}>
                        {`${entry.name}: ${valueFormatter ? valueFormatter(entry.value) : entry.value}`}
                    </p>
                ))}
            </div>
        );
    }

    return null;
};
const ReserviceRate = ({ data, loading, filterTable }) => {
    console.log("Load", data);
    const defaultTooltipStyles = {
        container: {
            backgroundColor: '#fff',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
        },
        label: {
            margin: 0,
            fontWeight: 'bold',
        },
        value: {
            margin: 0,
        },
    };
    const transformedData = data.reduce((acc, curr) => {
        const { timePeriod, location, reserviceRate } = curr;
        const existing = acc.find(item => item.timePeriod === timePeriod);
        if (existing) {
            existing[location] = parseFloat(reserviceRate);
        } else {
            acc.push({
                timePeriod,
                [location]: parseFloat(reserviceRate),
            });
        }
        return acc;
    }, []);
    const formatXAxisLabel = (tick) => {
        if (filterTable === 'weekly') {
            const weekString = `Week ${tick}`
            const weekWithoutYear = weekString?.replace(/-\d{4}/, '');
            return weekWithoutYear;
        } else if (filterTable === 'daily') {
            return moment(tick).format('MMMM D, YYYY');
        } else if (filterTable === 'monthly') {
            const monthNumber = parseInt(tick?.split("-")[1], 10);
            return monthNames[monthNumber - 1] || tick;
        }
        return tick; // Fallback in case no format is applied
    };
    const uniqueLocations = [...new Set(data.map(item => item.location))];
    return (
        <section style={{
            boxShadow: "0px 5px 14px 0px #0000000D"
        }} className='border border-dark p-4 rounded-4'>
            <div className='pb-3'>
                <h6 className='text-secondary mb-0 fs-12'>PERFORMANCE</h6>
                <PageHeading fontSize={"18px"} color={"#2D3748"} heading={"Reservice Rate"} />
            </div>
            {loading ? (
                <Skeleton height={400} width="100%" />
            ) : transformedData?.length === 0 ? (
                <div className="text-center text-secondary" style={{ height: 400 }}>
                    <h6>No data available</h6>
                </div>
            ) : (
                <ResponsiveContainer width="100%" height={400}>
                    <BarChart data={transformedData} margin={{ left: -30, top: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis
                            dataKey="timePeriod"
                            tickFormatter={formatXAxisLabel}
                            interval={0}
                            padding={{ left: 50, right: 20, bottom: 40 }}
                            tick={{ fontSize: 8, angle: -20, textAnchor: 'end' }}
                            ticks={data?.length > 12 ? data?.filter((_, index) => index % Math.ceil(data?.length / 12) === 0)?.map(item => item.timePeriod) : undefined}
                        />
                        <YAxis />
                        <Tooltip
                            content={
                                <CustomTooltip tooltipStyles={defaultTooltipStyles} filterTable={filterTable} />
                            }
                        />
                        <Legend />
                        {/* Dynamically render a Bar for each location */}
                        {uniqueLocations.map((location, index) => (
                            <Bar
                                key={location}
                                dataKey={location}
                                fill={colorPalette[index % colorPalette?.length]} // Use color from palette
                                strokeWidth={2}
                                radius={[10, 10, 0, 0]}
                                barSize={20}
                                name={location} // Set the name for the legend
                                activeBar={<Rectangle fill={lightenColor(colorPalette[index % colorPalette.length], 30)} />}
                            />
                        ))}
                    </BarChart>
                </ResponsiveContainer>
            )}
        </section>
    );
};

export default ReserviceRate;
