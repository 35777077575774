import Filter from 'components/shared/Filter'
import TableHeading from 'components/shared/TableHeading'
import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { startOfYear, endOfYear } from 'date-fns';
import Pagination from 'components/shared/Pagination';
import DashboardTable from 'components/shared/Table';
import { GetCompanyLocation, getReserviceCause } from 'services/stats/stats.service';
import { useParams } from 'react-router-dom';
import moment from 'moment';

const ReserviceDetailed = () => {
    const [selectedRange, setSelectedRange] = useState([startOfYear(new Date()), endOfYear(new Date())]);
    const { id } = useParams();
    const [loading, setLoading] = useState(true);
    const [filter, setFilter] = useState('All');
    const [filters, setFilters] = useState()
    const [filterTable, setFilterTable] = useState('weekly');
    const [currentPage, setCurrentPage] = useState(1);
    const rowsPerPage = 10;
    const [techData, setTechData] = useState([]);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const filtersTable = ['weekly', 'daily', 'monthly']
    const tableHeadings = [
        "Date",
        "Location",
        "Appt Id",
        "Tech Time on Site",
        "Date Completed",
    ];
    const tableBody = techData?.map((location) => [
        location?.week ? `Week ${location.week}` :
            location?.date ? moment(location.date).format('dddd, MMMM D, YYYY') :
                location?.month ? monthNames[location.month - 1] : '',
        location?.location,
        location?.appointmentID,
        `${location?.timeonsite ? parseFloat(location.timeonsite).toFixed(2) : '0'}`,
        // location?.timeonsite,
        location?.dateCompleted ? moment(location?.dateCompleted).format('LLL') : '',
    ]) || [];
    const fetchTech = async (formattedStartDate, formattedEndDate) => {
        setLoading(true);
        try {
            const response = await getReserviceCause(formattedStartDate, formattedEndDate, filterTable, filter, id);
            console.log("Tech Reservice", response?.data?.data)
            setTechData(response?.data?.data);
        } catch (error) {
            console.error("Error fetching production data:", error);
        } finally {
            setLoading(false);
        }
    };
    const indexOfLastRow = currentPage * rowsPerPage;
    const indexOfFirstRow = indexOfLastRow - rowsPerPage;
    const currentTableBody = tableBody.slice(indexOfFirstRow, indexOfLastRow);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(tableBody.length / rowsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const fetchLocation = async () => {
        try {
            setLoading(true);
            const response = await GetCompanyLocation();
            console.log("Response Location", response?.data?.locations)
            const locationsWithAll = [{ name: 'All', id: 'All' }, ...response?.data?.locations];
            setFilters(locationsWithAll);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }
    useEffect(() => {
        if (selectedRange && selectedRange?.length === 2) {
            const [startDate, endDate] = selectedRange;
            setCurrentPage(1);
            fetchLocation()
            fetchTech(startDate.toISOString().split('T')[0], endDate.toISOString().split('T')[0]);
        }
        // eslint-disable-next-line
    }, [selectedRange, filterTable, filter]);
    return (
        <Container className='mb-5' fluid>
            <Row className="mt-2">
                <Row className='allign-items-center'>
                    <Col >
                        <h1>Re Service Caused</h1>
                    </Col>
                    <Col >
                        <Filter selectedRange={selectedRange} setSelectedRange={setSelectedRange} filters={filters} setFilter={setFilter} activeFilter={filter} filtersTable={filtersTable} setFilterTable={setFilterTable} filterTable={filterTable} />
                    </Col>

                </Row>
            </Row>
            <Row>
                <div className='p-md-5 bg-white rounded-4 m-1'>
                    <div className='d-md-flex justify-content-between  align-items-center mb-md-4'>
                        <TableHeading heading={"All Re Service Caused By Tech"} />
                        <Pagination
                            currentPage={currentPage}
                            totalPages={Math.ceil(tableBody?.length / rowsPerPage)}
                            onNext={handleNextPage}
                            onPrevious={handlePreviousPage}
                            loading={loading}
                        />
                    </div>
                    <DashboardTable tableHeadings={tableHeadings} tableBody={currentTableBody} loading={loading} />
                </div>
            </Row>

        </Container >
    )
}

export default ReserviceDetailed
