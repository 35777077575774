import ConfirmModal from 'components/modals/ConfirmModal';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Container, Row, Col, Alert } from 'react-bootstrap';

const EditLocationModal = ({ show, close, location, handleEditLocation, loading, errorResponse }) => {
    const [error, setError] = useState({});
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [formData, setFormData] = useState({
        name: '', authenticationKey: '', authenticationToken: ''
    });

    useEffect(() => {
        if (location) {
            setFormData({
                name: location.name,
                authenticationKey: location.authenticationKey,
                authenticationToken: location.authenticationToken,
            });
        }
    }, [location]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setError((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[name];
            return newErrors;
        });
        setFormData({ ...formData, [name]: value });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.name) formErrors.name = 'Location name is required';
        if (!formData.authenticationKey) formErrors.authenticationKey = 'Authentication key is required';
        if (!formData.authenticationToken) formErrors.authenticationToken = 'Authentication token is required';
        return formErrors;
    };
    const handleSubmit = () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setError(formErrors);
        } else {
            // handleEditLocation(formData);
            setShowDeleteModal(true);
        }
    };
    const handleCancel = () => {
        close();
        setError({});
    };
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleConfirm = () => {
        setShowDeleteModal(false)
        handleEditLocation(formData);
    }
    return (
        <>
            <Modal show={show} onHide={close} centered size="lg" className='py-0 my-0'>
                <Modal.Header className='border-0' closeButton />
                <Container>
                    <Form className='pb-3 px-4'>
                        <h3 className='text-center mb-4 text-box' style={{ color: "var(--primary-color)" }}>Edit Location</h3>
                        <Row>
                            <Col xs={12} lg={6}>
                                <Form.Group controlId="formLocationName">
                                    <Form.Label className='text-secondary'>Location Name*</Form.Label>
                                    <Form.Control
                                        size='lg'
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        isInvalid={!!error.name}
                                    />
                                    <Form.Control.Feedback type="invalid">{error.name}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} lg={6}>
                                <Form.Group controlId="formAuthenticationKey">
                                    <Form.Label className='text-secondary'>Authentication Key*</Form.Label>
                                    <Form.Control
                                        size='lg'
                                        type="text"
                                        name="authenticationKey"
                                        value={formData.authenticationKey}
                                        onChange={handleChange}
                                        isInvalid={!!error.authenticationKey}
                                    />
                                    <Form.Control.Feedback type="invalid">{error.authenticationKey}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row className='my-3'>
                            <Col md={6} xs={12}>
                                <Form.Group controlId="formAuthenticationToken">
                                    <Form.Label className='text-secondary'>Authentication Token*</Form.Label>
                                    <Form.Control
                                        size='lg'
                                        type="text"
                                        name="authenticationToken"
                                        value={formData.authenticationToken}
                                        onChange={handleChange}
                                        isInvalid={!!error.authenticationToken}
                                    />
                                    <Form.Control.Feedback type="invalid">{error.authenticationToken}</Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        {errorResponse && <Alert variant="danger" className="text-center">{errorResponse}</Alert>}
                        <div className="d-flex justify-content-between pt-2">
                            <Button onClick={handleCancel} className='bg-secondary border-0 px-4 py-2' disabled={loading}>Cancel</Button>
                            <Button type="button" className='bg-primary-ltr border-0 px-4 py-2 submit-btn-hover' onClick={handleSubmit} disabled={loading}>
                                {loading ? 'Saving...' : 'Save'}
                            </Button>
                        </div>
                    </Form>

                </Container>
            </Modal>
            <ConfirmModal
                show={showDeleteModal}
                text='Are You sure You want to Edit this Location?'
                handleClose={handleCloseDeleteModal}
                handleConfirm={handleConfirm}
            />
        </>
    );
};

export default EditLocationModal;
