import React from 'react';
import { Table } from 'react-bootstrap';
import DashboardTableSkeleton from './DashboardTableSkeleton';

const DashboardTable = ({ tableBody, tableHeadings, loading, techData }) => {
    console.log("table body", techData);

    return (
        <>
            {loading ? (
                <DashboardTableSkeleton rowCount={5} columnCount={tableHeadings?.length<=5?tableHeadings?.length:5} />
            ) : (
                <div className="shadow p-3 rounded-3">
                    <Table hover responsive>
                        <thead>
                            <tr>
                                {tableHeadings?.map((th, idx) => (
                                    <th style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }} key={idx}>{th}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableBody && tableBody.length > 0 ? (
                                tableBody.map((row, i) => (
                                    <tr key={i}>
                                        {/* For the first column, render the value only if it's not the same as the previous row */}
                                        <td style={{ color: "#718096" }}>
                                            {i === 0 || row[0] !== tableBody[i - 1][0] ? row[0] : ''}
                                        </td>
                                        {/* Render other columns normally */}
                                        {row.slice(1).map((cell, j) => (
                                            <td key={j + 1} style={{ color: "#718096" }}>
                                                {cell}
                                            </td>
                                        ))}
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={tableHeadings?.length} className="text-center text-secondary py-3 border-bottom-0">
                                        No data available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </div>
            )}
        </>
    );
};

export default DashboardTable;
