import { useState } from "react";
import { Modal, Button, Form, Row, Col, Alert } from "react-bootstrap";
import { CountrySelect } from "react-country-state-city";
import PhoneInput from "react-phone-input-2";
import { UpdateCompany } from "services/admin/admin.service";

const EditCompanyModal = ({ show, onHide, company }) => {
    const [formData, setFormData] = useState({
        companyName: company?.name || "",
        companyAddress: company?.address || "",
        country: company?.country || "",
        companyPhone: company?.phone || "",
        maxUser: company?.maxUser || "",
    });
    const [error, setError] = useState({});
    const [loading, setLoading] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError({});

        if (!formData.companyName) {
            setError({ companyName: "Company Name is required" });
            return;
        }
        if (!formData.companyAddress) {
            setError({ companyAddress: "Company Address is required" });
            return;
        }
        if (!formData.country) {
            setError({ country: "Country is required" });
            return;
        }
        if (!formData.companyPhone) {
            setError({ companyPhone: "Company Phone Number is required" });
            return;
        }

        setLoading(true);
        try {
            await UpdateCompany(formData, company.id);
            setLoading(false);
            onHide();
        } catch (error) {
            setError({ general: "There was an error updating the company." });
            setLoading(false);
        }
    };

    return (

        <Modal show={show} onHide={onHide} centered size="lg" className='py-0 my-0'>
            <Modal.Header className='border-0 mb-0 pb-0' closeButton >
                {/* <Modal.Title>
                    <h3 className='text-center text-box' style={{ color: "var(--primary-color)" }}>Edit Company</h3>
                </Modal.Title> */}
            </Modal.Header>
            <Modal.Body>
                {error.general && <Alert variant="danger">{error.general}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <h3 className='text-center text-box pb-2' style={{ color: "var(--primary-color)" }}>Edit Company</h3>
                    <Row className="my-3">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyName">
                                <Form.Label className="text-secondary">Company Name*</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    name="companyName"
                                    value={formData.companyName}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.companyName}
                                />
                                <Form.Control.Feedback type="invalid">{error.companyName}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCompanyAddress">
                                <Form.Label className="text-secondary">Company Address*</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="text"
                                    name="companyAddress"
                                    value={formData.companyAddress}
                                    onChange={handleInputChange}
                                    isInvalid={!!error.companyAddress}
                                />
                                <Form.Control.Feedback type="invalid">{error.companyAddress}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formCountry" className='custom-input custome-phone'>
                                <Form.Label className="text-secondary">Country*</Form.Label>
                                <CountrySelect
                                    inputClassName="border-0"
                                    placeHolder="Select Country"
                                    value={formData.country}
                                    onChange={(country) => {
                                        setFormData({ ...formData, country: country.name });
                                        setError((prevErrors) => ({
                                            ...prevErrors,
                                            country: '',
                                        }));
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{error.country}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Form.Group className='custome-phone'>
                                <Form.Label className="text-secondary">Company Phone Number*</Form.Label>
                                <PhoneInput
                                    inputClass='custome-phone py-4'
                                    country={'all'}
                                    placeholder='Enter Company phone number'
                                    value={formData.companyPhone}
                                    onChange={(phone) => {
                                        setFormData({ ...formData, companyPhone: phone });
                                        setError((prevErrors) => ({
                                            ...prevErrors,
                                            companyPhone: '',
                                        }));
                                    }}
                                />
                                <Form.Control.Feedback type="invalid">{error.companyPhone}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col xs={12} lg={6}>
                            <Form.Group controlId="formMaxUser">
                                <Form.Label className="text-secondary">Maximum User</Form.Label>
                                <Form.Control
                                    size="lg"
                                    type="number"
                                    name="maxUser"
                                    value={formData.maxUser}
                                    onChange={handleInputChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-between pt-2 mt-3">
                        <Button onClick={onHide} className='bg-secondary border-0 px-4 py-2' disabled={loading}>Cancel</Button>
                        <Button type="submit" className='bg-primary-ltr border-0 px-4 py-2 submit-btn-hover' disabled={loading}>
                            {loading ? 'Saving...' : 'Save'}
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditCompanyModal;
