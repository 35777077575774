import React from 'react';
import { DateRangePicker } from 'rsuite';
import { startOfDay, endOfDay, addDays, subDays, subMonths, startOfMonth, endOfMonth, startOfYear, endOfYear } from 'date-fns';
import './DateRange.css';
import { useMediaQuery } from 'react-responsive';

const Ranges = [
    {
        label: 'Today',
        value: [startOfDay(new Date()), endOfDay(new Date())],
        placement: 'left',
        // style: { backgroundColor: '#e0f7fa', color: '#00796b' },
    },
    {
        label: 'Yesterday',
        value: [startOfDay(addDays(new Date(), -1)), endOfDay(addDays(new Date(), -1))],
        placement: 'left',
        // style: { backgroundColor: '#e0f7fa', color: '#00796b' },
    },
    {
        label: 'Last 7 Days',
        value: [startOfDay(subDays(new Date(), 6)), endOfDay(new Date())],
        placement: 'left',
        // style: { backgroundColor: '#e0f7fa', color: '#00796b' },
    },
    {
        label: 'This Month',
        value: [startOfMonth(new Date()), endOfMonth(new Date())],
        placement: 'left',
        // style: { backgroundColor: '#e0f7fa', color: '#00796b' },
    },
    {
        label: 'Last Month',
        value: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1))
        ],
        placement: 'left',
        // style: { backgroundColor: '#e0f7fa', color: '#00796b' },
    },
    {
        label: 'This Year',
        value: [startOfYear(new Date()), endOfYear(new Date())],
        placement: 'left',
    },
];

export const DateRange = ({ selectedRange, setSelectedRange }) => {
    const handleSelect = (range) => {
        setSelectedRange(range);
    };

    const isSmallOrMedium = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <div className='bg-transparent p-1'>
            <DateRangePicker
                size='md'
                placeholder='Select Date Range'
                block
                value={selectedRange}
                ranges={Ranges}
                placement="bottomEnd"
                showOneCalendar={isSmallOrMedium}
                onChange={handleSelect}
                className="custom-date-range-picker"
                style={{ width: 180, left: '1106.12px !important' }}
            />
        </div>
    );
};
