import React from 'react'
import { MdOutlineCalendarToday } from 'react-icons/md';
import { DateRange } from './DateRange';
import { Button, Col, Dropdown, Form, Row } from 'react-bootstrap';
const Filter = ({ selectedRange, setSelectedRange, filters, setFilter, activeFilter, filtersTable, setFilterTable, filterTable }) => {
    console.log("Filres location", filters)
    return (
        <>
            <div className="d-flex flex-xl-row flex-column justify-content-xl-end align-items-center gap-3">
                <div>
                    <Row className='' >
                        <Col xs={12}>
                            <Form className='d-flex gap-3 align-items-center justify-content-between'>

                                <div className='d-flex'>
                                    {filtersTable?.map((filter, index) => {
                                        const buttonClass = `rounded-4 ms-2 px-4 py-2  btn-custom-hover text-capitalize  ${filter === filterTable ? "btn-custom" : "btn-secondary"}`;

                                        return (
                                            <Button
                                                className={buttonClass}
                                                key={index}
                                                onClick={() => setFilterTable(filter)}
                                            >
                                                {filter}
                                            </Button>
                                        );
                                    })}
                                </div>
                            </Form>
                        </Col>
                    </Row >
                </div>
                <div className='d-flex gap-3 flex-md-row flex-column'>
                    <div className='d-flex align-items-center'>
                        <Dropdown>
                            <Dropdown.Toggle className='rounded-3 filter-sort px-3'>
                                {activeFilter}
                            </Dropdown.Toggle>

                            <Dropdown.Menu className='py-0'>
                                {filters?.map((filter, index) => {
                                    return <Dropdown.Item className='border-top text-center text-decoration-none' key={index} onClick={() => setFilter(filter?.name)}>{`${filter?.name}`}</Dropdown.Item>;
                                })}

                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div
                        style={{ borderColor: "var(--color5)!important", height: "40px" }}
                        className="border d-flex rounded-4 align-items-center "
                    >
                        <div className="border-end px-3 py-2 d-md-block d-none">
                            <MdOutlineCalendarToday className="fs-4" color="var(--color5)" />
                        </div>
                        <DateRange setSelectedRange={setSelectedRange} selectedRange={selectedRange} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Filter
