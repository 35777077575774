import AddLocationModal from 'components/modals/AddLocationModal';
import ConfirmModal from 'components/modals/ConfirmModal';
import EditLocationModal from 'components/shared/EditLocationTable';
import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { MdDeleteForever, MdEdit } from 'react-icons/md';
import { toast } from 'react-toastify';
import { deleteLocation, updateLocation } from 'services/admin/admin.service';

const LocationTable = ({ company, reCall, companyId }) => {
    console.log("Location Table", company)
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [errorResponse, setErrorResponse] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [locationIndexToEdit, setLocationIndexToEdit] = useState(null);
    const [locationToDeleteIndex, setLocationToDeleteIndex] = useState(null);
    const handleDeleteLocation = async () => {
        const toastId = toast.loading("Deleting Location...");
        if (locationToDeleteIndex !== null) {
            setLoading(true);
            console.log("Editing location at index: ", locationToDeleteIndex);
            try {
                await deleteLocation(companyId, locationToDeleteIndex);
                reCall();
                setShowDeleteModal(false);
                setLoading(false);
                toast.update(toastId, {
                    render: "Location Deleted successfully!",
                    type: "success",
                    isLoading: false,
                    autoClose: 3000,
                    closeOnClick: true,
                });
            } catch (err) {
                toast.update(toastId, {
                    render: err?.response?.data?.error || 'Network Error',
                    type: "error",
                    isLoading: false,
                    autoClose: 3000,
                    closeOnClick: true,
                });
                console.error('Error:', err);
                setShowDeleteModal(false);
                setLoading(false)
            }
        }
    };

    const handleEditLocation = async (updatedLocation) => {
        setLoading(true);
        setErrorResponse(null);
        console.log("Editing location at index: ", locationIndexToEdit);
        console.log("Edit data", updatedLocation)
        try {
            await updateLocation(companyId, locationIndexToEdit, updatedLocation); // Use the index to update
            reCall();
            setShowEditModal(false);
            setLoading(false);
        } catch (err) {
            setErrorResponse(err?.response?.data?.error || 'Network Error');
            console.error('Error:', err);
            setShowEditModal(false);
            setLoading(false)
        }

    };
    const FormClose = () => setShowAddModal(false);
    const handleCloseDeleteModal = () => setShowDeleteModal(false);
    const handleCloseEditModal = () => setShowEditModal(false);

    return (
        <>
            {/* <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Locations</h4>
                <Button onClick={() => setShowAddModal(true)}>Add Location</Button>
            </div> */}
            <div className="d-flex align-items-center justify-content-between mb-3">
                <h4 style={{ color: '#4E4E4E' }} className="mt-4">
                    Location Info
                </h4>
                <Button
                    className="btn-custom px-3 fw-bold d-flex align-items-center"
                    onClick={() => setShowAddModal(true)}
                    style={{ backgroundColor: "#007bff", borderColor: "#007bff" }}
                >
                    <span className="me-2">&#43;</span>
                    Add Location
                </Button>
            </div>
            <div className="shadow p-3 rounded-3 mb-4">
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th className='t-head py-3' style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }}>Name</th>
                            <th className='t-head py-3' style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }}>Key</th>
                            <th className='t-head py-3' style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }}>Token</th>
                            <th className='t-head py-3' style={{ backgroundColor: "#F7FAFC", color: "#A0AEC0" }}>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {company?.locations && company?.locations?.length > 0 ? (
                            company?.locations?.map((location, index) => (
                                <tr key={index}>
                                    <td className='py-3'>{location?.name}</td>
                                    <td className='py-3'>{location?.authenticationKey}</td>
                                    <td className='py-3'>{location?.authenticationToken}</td>
                                    <td>
                                        <Button
                                            onClick={() => {
                                                setShowEditModal(true);
                                                setLocationIndexToEdit(index); // Set the index for editing
                                                setErrorResponse(null)
                                            }}
                                            className='text-primary bg-transparent border-0 p-0 me-2'
                                        >
                                            <MdEdit />
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                setShowDeleteModal(true);
                                                setLocationToDeleteIndex(index); // Set the index for deletion
                                            }}
                                            className='text-danger bg-transparent border-0 p-0'
                                        >
                                            <MdDeleteForever />
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4} className="text-center py-3">
                                    No Location available.
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <EditLocationModal
                show={showEditModal}
                close={handleCloseEditModal}
                location={company?.locations[locationIndexToEdit]} // Get the location data using the index
                handleEditLocation={handleEditLocation} // Function to handle editing
                loading={loading}
                errorResponse={errorResponse}
            />
            <AddLocationModal
                reCall={reCall}
                companyId={companyId}
                show={showAddModal}
                close={FormClose}
            />

            {/* Delete Confirmation Modal */}
            <ConfirmModal
                show={showDeleteModal}
                text='Do you really want to delete this location?'
                handleClose={handleCloseDeleteModal}
                handleConfirm={handleDeleteLocation}
            />
        </>
    );
};

export default LocationTable;
