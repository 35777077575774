import React, { useState, useRef } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import OffCanvas from './OffCanvas';
import SidebarHeader from './SidebarHeader';
import ScrollTopComponent from 'utils/ScrollTopComponent';

const DashboardLayout = () => {
    const [showSidebar, setShowSidebar] = useState(false);
    const containerRef = useRef(null); // Create a ref for the scrollable container

    const handleShowSidebar = () => setShowSidebar(true);
    const handleCloseSidebar = () => setShowSidebar(false);

    return (
        <div className='d-flex flex-column '>
            <div className='d-lg-flex vh-100'>
                <div className="d-none d-lg-block">
                    <Sidebar />
                </div>

                <div className='d-flex flex-column' style={{ flex: 1 }}>
                    <div className='px-3 py-md-2'>
                        <SidebarHeader onLogoClick={handleShowSidebar} />
                    </div>

                    {/* Pass ref to this div */}
                    <div ref={containerRef} className='px-3 py-md-2' style={{ overflow: 'auto', height: '90vh' }}>
                        <Outlet />
                    </div>
                </div>
            </div>

            <OffCanvas showSidebar={showSidebar} handleCloseSidebar={handleCloseSidebar} />
            
            {/* Include ScrollTopComponent and pass the ref */}
            <ScrollTopComponent containerRef={containerRef} />
        </div>
    );
}

export default DashboardLayout;
